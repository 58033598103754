<template>
  <div class="create-content-main">
    <div class="setting-menu background-content-admin">
      <!-- <div class="mb-2 justify-content-between row">
        <h2 class="col-12 col-md-8 font-title font-weight-bold my-auto">
          メニュー管理
        </h2>
        <div class="col-12 col-md-4 tex-left text-md-right text-sm-left">
          <b-button
            class="button-back-dashboard ml-0"
            v-on:click="gotoDashboard()"
            >戻る</b-button
          >
        </div>
      </div> -->
      <div class="row mx-3">
        <h2
          class="col-md-8 col-12 font-title font-weight-bold pl-0 my-auto header-font"
        >
          メニュー管理
        </h2>
        <div class="col-md-4 col-12 px-0">
          <b-button
            class="button-back-dashboard float-md-right back-btn"
            v-on:click="gotoDashboard()"
          >
            戻る
          </b-button>
        </div>
      </div>
      <div class="content-setting-header pt-4">
        <h4 class="mb-3 font-title-default label-title">
          ヘッダー表示メニュー
        </h4>
        <div class="container" v-if="listHeader && listHeader.length > 0">
          <div
            class="row my-2"
            v-for="(value, index) in listHeader"
            :key="index"
          >
            <div class="col-2 col-md-1 my-auto">
              <b-button
                variant="dark"
                class="rounded-circle custome-btn-sub"
                style="border-radius: 50% !important"
                v-on:click="subHeader(index)"
                ><b-icon icon="dash" scale="2"
              /></b-button>
            </div>
            <div class="col-10 col-md-6 my-auto">
              <b-form-select
                class="w-100 custome-text-select"
                id="select-cate"
                v-model="value.page_title"
                tag-placeholder="これを新しいヘッダーとして追加します"
                :options="listPageHeaderOptions"
                v-on:change="setSelect($event)"
              >
              </b-form-select>
            </div>
            <div class="col-2 col-md-1 frame-icon my-auto">
              <div v-if="value.icon">
                <div v-if="isImage(value.icon)" class="float-lg-right img-menu">
                  <img
                    width="100%"
                    height="100%"
                    :src="value.icon"
                    alt=""
                    style="object-fit: contain"
                  />
                </div>
                <b-icon
                  v-else
                  :icon="`${value.icon}`"
                  font-scale="3"
                  class="float-lg-right icon-menu"
                />
              </div>
            </div>
            <div class="col-5 col-md-2 btn-upload-icon btn-open">
              <b-button
                class="float-right"
                style="word-break: keep-all"
                variant="success"
                v-b-modal.modal-add-icon
                v-on:click.prevent="openIcon(index)"
                >アイコン選択</b-button
              >
            </div>
            <div class="col-5 col-md-2 btn-upload-icon btn-up">
              <b-button
                class="float-right"
                style="word-break: keep-all"
                variant="success"
                v-on:click.prevent="uploadIcon(index)"
                >アップロード</b-button
              >
              <input
                type="file"
                hidden
                ref="selectFile"
                id="idSelectFile"
                accept="image/jpg, image/png, image/jpeg"
                @change="changeIcon($event, idxHeader)"
              />
            </div>
            <div
              class="col-12 mx-auto justify-content-center mt-2 setting-slag"
            >
              <div class="setting-show-slag d-flex justify-content-center">
                <p class="my-auto">表示設定</p>
                <div class="custom-select-2 custome-multi-slag">
                  <multiselect
                    v-model="value.slag_display"
                    tag-placeholder="スラグを追加"
                    placeholder=""
                    label="slag_name"
                    track-by="slag_name"
                    :options="listSlagOptions"
                    :multiple="true"
                    :taggable="false"
                    :showNoResults="false"
                    :max-height="200"
                    select-label="選択"
                    selected-label=""
                    deselect-label="削除"
                  >
                    <template v-slot:no-options> リストは空です </template>
                  </multiselect>
                </div>
              </div>
              <div class="setting-hidden-slag d-flex justify-content-center">
                <p class="my-auto">非表示設定</p>
                <div class="custom-select-2 custome-multi-slag">
                  <multiselect
                    v-model="value.slag_non_display"
                    tag-placeholder="スラグを追加"
                    placeholder=""
                    label="slag_name"
                    track-by="slag_name"
                    :options="listSlagOptions"
                    :multiple="true"
                    :taggable="false"
                    :showNoResults="false"
                    :max-height="200"
                    select-label="選択"
                    selected-label=""
                    deselect-label="削除"
                  >
                    <template v-slot:no-options> リストは空です </template>
                  </multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-center my-4">
            <b-button
              variant="success"
              class="rounded-circle custome-btn-add"
              style="border-radius: 50% !important"
              v-on:click="addHeader()"
              ><b-icon class="sidebar-icon" icon="plus" font-scale="2"
            /></b-button>
          </div>
          <div class="d-flex justify-content-center">
            <b-button
              class="btn-color"
              type="submit"
              :disabled="isLoading"
              variant="success"
              v-on:click.prevent="updateHeader()"
            >
              <b-spinner v-if="isLoading" small></b-spinner>
              <span>編集内容を反映する</span>
            </b-button>
          </div>
        </div>
      </div>
      <!-- Footer Setting -->
    </div>

    <!-- Modal Add Icon -->
    <b-modal
      id="modal-add-icon"
      class="modal-add-icon"
      size="lg"
      title="アイコン選択"
      hide-footer
      @show="resetModalIcon"
      @hidden="resetModalIcon"
    >
      <div class="list-icon" v-if="listIcons.length">
        <div class="ct-choose" v-for="(value, index) in listIcons" :key="index">
          <b-button
            variant="default"
            v-on:click="chooseIconHeader(value, index, idxHeader)"
          >
            <b-icon :icon="`${value}`" font-scale="2" />
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- Modal Add Icon -->
  </div>
</template>
<script>
import no_image from "@/assets/img/no-image.jpg";
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { Routes } from "../../utils/routes";
import { Urls } from "../../utils/urls.js";
import { Api } from "../../utils/http-common.js";
function initialState() {
  return {
    isLoading: false,
    no_image: no_image,
    active_el: "",
    listIcons: Constants.LIST_ICON,
    listPageHeaderOptions: [
      { text: "カート", value: "カート" },
      { text: "マイページ", value: "マイページ" },
      { text: "ニュース", value: "ニュース" },
      { text: "ポイント購入", value: "ポイント購入" },
      { text: "ノート", value: "ノート" },
      { text: "著者一覧", value: "著者一覧" },
      { text: "検索", value: "検索" },
      // { text: "ヘルプ", value: "ヘルプ" },
      { text: "お問い合わせ", value: "お問い合わせ" },
      { text: "質問", value: "質問" },
    ],
    idxHeader: null,
    selectedValue: null,
    listFP: [],
    listSlagOptions: [],
    shop_id: localStorage.getItem(Constants.SHOP_ID),
  };
}
export default {
  name: "headerSetting",
  components: {},
  data() {
    return initialState();
  },
  created() {
    const requestFP = {
      shop_id: this.shop_id,
    };
    this.getListFixed(requestFP);
    const request = {
      shop_id: this.shop_id,
    };
    this.getHeaderSetting(request);
    this.getALLlistSlag(request);
  },
  computed: {
    ...mapGetters([
      "listHeader",
      "listFixedPage",
      "listSlag",
      "message",
      "success",
      "error",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
      }
    },
    listFixedPage() {
      this.listFP = this.listFixedPage;
      this.listFP.forEach((item) => {
        var fixedPage = {
          text:
            item && item.title && item.title.length > Constants.MAX_LENGTH
              ? item.title.substr(0, Constants.MAX_LENGTH) + "..."
              : item.title,
          value: item.title,
        };
        this.listPageHeaderOptions.push(fixedPage);
      });
    },
    listHeader() {
      if (this.listHeader && this.listHeader.length == 0) {
        this.listHeader.push({
          page_title: "",
          icon: null,
          shop_id: this.shop_id,
          url: "",
          slag_display: [],
          slag_non_display: [],
        });
      } else {
        this.listHeader.forEach((header) => {
          if (header.slag_display && header.slag_display.length > 0) {
            header.slag_display.forEach((item) => {
              item.slag_name = item.slag_name
                ? item.slag_name
                : item.slag_name_en;
            });
          }
          if (header.slag_non_display && header.slag_non_display.length > 0) {
            header.slag_non_display.forEach((item) => {
              item.slag_name = item.slag_name
                ? item.slag_name
                : item.slag_name_en;
            });
          }
        });
      }
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
    },
  },
  methods: {
    ...mapActions({
      getListFixed: "getListFixed",
      getHeaderSetting: "getHeaderSetting",
      getALLlistSlag: "getALLlistSlag",
    }),
    openIcon(index) {
      this.idxHeader = index;
    },
    chooseIconHeader(key, index, idxHeader) {
      this.active_el = index;
      this.listHeader[idxHeader].icon = key;
      this.$bvModal.hide("modal-add-icon");
    },
    resetModalIcon() {
      this.active_el = "";
    },
    addHeader() {
      this.listHeader.push({
        page_title: "",
        icon: null,
        shop_id: this.shop_id,
        url: "",
        slag_display: [],
        slag_non_display: [],
      });
    },
    subHeader(index) {
      this.listHeader.splice(index, 1);
    },
    async updateHeader() {
      this.isLoading = true;
      let shop_id = this.shop_id;
      let isNotDomain = this.$route.params.shopId;
      var formHeader = new FormData();
      formHeader.append("shop_id", shop_id);
      for (var i = 0; i < this.listHeader.length; i++) {
        formHeader.append(
          "data[" + i + "][page_title]",
          this.listHeader[i]["page_title"]
        );
        formHeader.append("data[" + i + "][icon]", this.listHeader[i]["icon"]);
        formHeader.append("data[" + i + "][position]", i);
        var listHeaderUrl = [
          {
            name: "カート",
            url: this.$router.resolve({
              name: isNotDomain ? "default cart" : "default cart domain",
            }).href,
          },
          {
            name: "マイページ",
            url: this.$router.resolve({
              name: isNotDomain ? "my profile" : "my profile domain",
            }).href,
          },
          {
            name: "ニュース",
            url: this.$router.resolve({
              name: isNotDomain ? "list new user" : "list new user domain",
            }).href,
          },
          {
            name: "ポイント購入",
            url: this.$router.resolve({
              name: isNotDomain ? "history point" : "history point domain",
            }).href,
          },
          {
            name: "ノート",
            url: this.$router.resolve({
              name: isNotDomain
                ? "list note content"
                : "list note content domain",
            }).href,
          },
          {
            name: "著者一覧",
            url: this.$router.resolve({
              name: isNotDomain ? "ListAuthorStore" : "ListAuthorStore domain",
            }).href,
          },
          {
            name: "検索",
            url: this.$router.resolve({
              name: isNotDomain ? "library" : "library domain",
            }).href,
          },
          // {
          //   name: "ヘルプ",
          //   url: this.$router.resolve({
          //     name: isNotDomain ? "library" : "library domain",
          //   }).href,
          // },
          {
            name: "お問い合わせ",
            url: this.$router.resolve({
              name: isNotDomain
                ? "create inquiry user"
                : "create inquiry user domain",
            }).href,
          },
          {
            name: "質問",
            url: this.$router.resolve({
              name: isNotDomain
                ? "List Chat Content"
                : "List Chat Content domain",
            }).href,
          },
        ];
        this.listFP.forEach((item) => {
          var fixedPage = {
            name: item && item.title,
            url: this.$router.resolve({
              name: isNotDomain ? "staticPage" : "staticPage domain",
              params: { id: item.id },
            }).href,
            id: item && item.id,
          };
          listHeaderUrl.push(fixedPage);
        });
        listHeaderUrl.forEach((value) => {
          if (value.name == this.listHeader[i]["page_title"]) {
            formHeader.append(
              "data[" + i + "][url]",
              value.url.replace(Routes.SHOP + "/" + shop_id + "/", "")
            );
            if (typeof value.id != "undefined") {
              formHeader.append("data[" + i + "][fixed_page_id]", value.id);
            } else {
              formHeader.append("data[" + i + "][fixed_page_id]", "");
            }
            return;
          }
        });
        if (
          this.listHeader[i]["slag_display"] &&
          this.listHeader[i]["slag_display"].length > 0
        ) {
          this.listHeader[i]["slag_display"].forEach((itemSlag, index) => {
            formHeader.append(
              "data[" + i + "][slag_display][" + index + "]",
              itemSlag.id
            );
          });
        } else {
          formHeader.append("data[" + i + "][slag_display]", []);
        }
        if (
          this.listHeader[i]["slag_non_display"] &&
          this.listHeader[i]["slag_non_display"].length > 0
        ) {
          this.listHeader[i]["slag_non_display"].forEach((itemSlag, index) => {
            formHeader.append(
              "data[" + i + "][slag_non_display][" + index + "]",
              itemSlag.id
            );
          });
        } else {
          formHeader.append("data[" + i + "][slag_non_display]", []);
        }
      }
      const dataReturn = await this.$store.dispatch("updateHeader", formHeader);
      if (!dataReturn.success) {
        this.isLoading = false;
      } else {
        Object.assign(this.$data, initialState());
        this.$router.go(-1);
      }
    },

    setSelect(event) {
      this.listPageHeaderOptions.forEach((value) => {
        if (value.value == event) {
          value["disabled"] = true;
        }
      });
    },
    gotoDashboard() {
      Object.assign(this.$data, initialState());
      if (this.$route.params.shopId) {
        this.$router.push({ name: "menuManager" });
      } else {
        this.$router.push({ name: "menuManager domain" });
      }
    },
    uploadIcon(index) {
      this.idxHeader = index;
      document.getElementById("idSelectFile").click();
    },
    async changeIcon(e, idxHeader) {
      if (
        e.target.files[0].type != "image/jpg" &&
        e.target.files[0].type != "image/png" &&
        e.target.files[0].type != "image/jpeg"
      ) {
        this.$toasted.error("画像はjpeg、jpg、png形式である必要があります。");
      } else if (e.target.files[0].size / 1024 / 1024 > 10) {
        this.$toasted.error(
          "アップロード可能なファイルのサイズは10MBまでです。"
        );
      } else {
        var formData = new FormData();
        formData.append("image", e.target.files[0]);
        formData.append("id", this.shop_id);
        const url = `${Urls.ADMIN}/${Urls.SETTING_PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const { data } = response;
            if (data.success) {
              const url = data.data; // Get url from response
              this.listHeader[idxHeader].icon = url;
            } else {
              this.$toasted.error(data.message);
            }
          })
          .catch(() => {});
      }
    },
    isImage(url) {
      return /\.(jpg|jpeg|png|JPG|JPEG|PNG)$/.test(url);
    },
  },
};
</script>
